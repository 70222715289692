import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyCIR56ldy--K4qnGmFcXbtzuFdaatN1_6k",
    authDomain: "akcenthealthcare-7f91b.firebaseapp.com",
    projectId: "akcenthealthcare-7f91b",
    storageBucket: "akcenthealthcare-7f91b.appspot.com",
    messagingSenderId: "198958766472",
    appId: "1:198958766472:web:551c813576de53fd51023d"
});

const fb = firebase;

export default fb;