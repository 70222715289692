import React, { useState } from 'react'

const UpArrow = () => {

    const [show, setShow] = useState("");


    const changeNavBg = () => {
        window.scrollY >= 300 ? setShow("block") : setShow("none");
    }

    window.addEventListener('scroll', changeNavBg);

    const up = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <div className='uparrow' onClick={up} style={{ display: show }}>
            <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m437.02 74.98a256 256 0 0 0 -362.04 362.04 256 256 0 0 0 362.04-362.04zm-181.02 405.02c-123.514 0-224-100.486-224-224s100.486-224 224-224 224 100.486 224 224-100.486 224-224 224zm112.634-194.654a16 16 0 1 1 -22.627 22.627l-90.007-90.006-90.007 90.006a16 16 0 0 1 -22.627-22.627l101.321-101.32a16 16 0 0 1 22.626 0z" /></svg>
        </div>
    )
}

export default UpArrow
